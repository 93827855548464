/* eslint-disable @typescript-eslint/no-explicit-any */
// Hook odpowiedzialny za tworzenie listy zakupowej

import axios from 'api/axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { ICommandResponseError as IError, ICommandResponseSuccess } from 'api/types';

type IShoppingListType = 'FAVORITES' | 'NOT_AVAILABLE_PRODUCTS';

// typ zwracanych danych
type IResponse = ICommandResponseSuccess & {
  data: {
    id: number;
  };
};

const deleteShoppingListRemoveFromDefaultList = (
  shoppingListType: IShoppingListType,
  productId: number,
  unitId: number
): Promise<IResponse> =>
  axios.delete(
    `/shopping-lists/remove-from-default-shopping-list/${shoppingListType}/${productId}/${unitId}`
  );

export const useDeleteShoppingListRemoveFromDefaultList = (
  shoppingListType: IShoppingListType,
  productId: number,
  unitId: number,
  options?: UseMutationOptions<IResponse, IError>
) =>
  useMutation(
    () => deleteShoppingListRemoveFromDefaultList(shoppingListType, productId, unitId),
    options
  );
