// Hook odpowiedzialny za pobranie listy kategorii

import axios from 'api/axios';
import { useQuery, UseQueryOptions } from 'react-query';
import {
  ICommandResponseError as IError,
  IPaginationRequest,
  IPaginationResponse
} from 'api/types';

// parametry requestu do api
export type IRequest = IPaginationRequest;

export type ICategorySubcategoryListItem = {
  id: number;
  symbol: string;
  name: string;
  type: string;
  images: {
    normal: string;
    menu_icon: string;
  };
  url_link: string;
};

// typ zwracanych danych
type IResponse = IPaginationResponse<ICategorySubcategoryListItem>;

const getProductsCategorySubcategories = (id: number, params?: IRequest): Promise<IResponse> =>
  axios.get(`/products/category/${id}/subcategories`, { params });

export const useGetProductsCategorySubcategories = (
  id: number,
  params?: IRequest,
  options?: UseQueryOptions<IResponse, IError>
) =>
  useQuery<IResponse, IError>(
    ['products-categories-subcategories', id],
    () => getProductsCategorySubcategories(id, params),
    options
  );
