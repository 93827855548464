// Produkt wyświetlany na listach i sliderach

import React, { useState, FC, useMemo, useCallback, useEffect } from 'react';
import classnames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';

import { useSelector } from 'store';
import { useRWD } from 'hooks';
import {
  usePostProductQuantityDecrement,
  usePostProductQuantityIncrement,
  usePostProductQuantity,
  usePostShoppingListAddToDefaultList
} from 'api';
import { IProductListItem, IUnit, IProductMainAttribute } from 'api/types';
import {
  AddToCartButton,
  AddToShoppingListButton,
  ProductPreview,
  AddToFavouritesButton
} from 'components/containers';
import { Select, Counter, Link, Label, Modal } from 'components/controls';

import { HtmlBlock } from 'components/containers/HtmlBlock';

import styles from 'theme/components/containers/ProductItem/ProductItem.module.scss';

// typ danych wejściowych
interface Props {
  product: IProductListItem;
  categoryId?: number;
  searchKeywords?: string;
  line?: boolean;
  minimalVariant?: boolean;
  imageStretch?: boolean;
  slide?: boolean;
  hidePricesEnabled?: boolean;
  isSearch?: boolean;
  showProductPreview?: boolean;
}

const ProductItem: FC<Props> = ({
  product,
  line,
  minimalVariant,
  imageStretch,
  slide,
  categoryId,
  searchKeywords,
  hidePricesEnabled,
  isSearch,
  showProductPreview
}) => {
  const { t } = useTranslation();
  const { profile } = useSelector((state) => state.auth);
  const { isMobile, isTablet } = useRWD();

  const { hidePrices } = useSelector((state) => state.products);

  const [isAvailabilityButtonDisabled, setIsAvailabilityButtonDisabled] = useState(
    product.is_on_not_available_products_shopping_list
  );

  // czy rozwinięte są szczegóły
  const [isWhereToBuyModal, setIsWhereToBuyModal] = useState(false);

  // czy widoczny jest podgląd produktu
  const [isProductPreview, setIsProductPreview] = useState(false);

  // aktualna ilość
  const [quantity, setQuantity] = useState(0);

  // aktualnie zmieniona ilość (sprzed walidacji)
  const [manuallyChangedQuantity, setManuallyChangedQuantity] = useState<number>();

  // ID aktualnego magazynu
  const [warehouseId, setWarehouseId] = useState<number>(product.units[0]?.warehouse_id);

  // aktualna jednostka
  const unit = useMemo(
    () => product.units.find((unit) => unit.warehouse_id === warehouseId),
    [warehouseId, product]
  );

  // aktualna jednostka
  const unitId = unit?.unit_id || 0;

  // czy są widoczne ceny
  const disablePrice = hidePricesEnabled && hidePrices;

  // ustawianie początkowego magazynu
  useEffect(() => {
    const centralWarehouseStock = product.units.find(
      (unit) => unit.is_central_warehouse
    )?.available_quantity;

    if (!centralWarehouseStock && product.is_available) {
      const firstAvailableUnit = product.units.find((unit) => !!unit.available_quantity);

      firstAvailableUnit && setWarehouseId(firstAvailableUnit?.warehouse_id);
    }
  }, []);

  // zmiana ilości po zmianie jednostki
  useEffect(() => {
    unit && setQuantity(unit.minimal_quantity || 1);
  }, [unit]);

  // pobranie informacji o zmniejsonej ilości
  const { mutate: decrementQuantity, isLoading: isDecrementQuantityLoading } =
    usePostProductQuantityDecrement(product.id, {
      onSuccess: (data) => {
        setQuantity(data.data.value);
      }
    });

  // pobranie informacji o zwiększonej ilości
  const { mutate: incrementQuantity, isLoading: isIncrementQuantityLoading } =
    usePostProductQuantityIncrement(product.id, {
      onSuccess: (data) => {
        setQuantity(data.data.value);
      }
    });

  // pobranie informacji ilości
  const {
    mutate: updateQuantity,
    mutateAsync: updateQuantityAsync,
    isLoading: isUpdateQuantityLoading
  } = usePostProductQuantity(product.id, {
    onSuccess: (data) => {
      setQuantity(data.data.value);
    }
  });

  // dodanie do listy niedostępnych produktów
  const { mutate: addToNotAvailableList } = usePostShoppingListAddToDefaultList({
    onSuccess: () => {
      setIsAvailabilityButtonDisabled(true);
    }
  });

  // przypysanie efektu timeout do zmiennej
  let imageHoverTimeout: null | ReturnType<typeof setTimeout> = null;

  const setImageHover = (condition: boolean) => {
    if (imageHoverTimeout) {
      clearTimeout(imageHoverTimeout);
    }

    imageHoverTimeout = setTimeout(() => {
      setIsProductPreview(condition);
    }, 500);
  };

  // funkcja renderująca dropdown jednostek
  const renderStockSelector = useCallback(() => {
    return (
      <div
        className={classnames(styles['unit-selector-wrapper'], {
          [styles.unitSelectorWrapperLine]: !!line,
          [styles.unitSelectorWrapperMinimal]: !!minimalVariant,
          [styles.unitSelectorWrapperLine]: !!line
        })}>
        {profile?.role !== 'ROLE_OPEN_PROFILE' && (
          <Select<IUnit>
            onChange={(unit) => unit?.warehouse_id && setWarehouseId(unit.warehouse_id)}
            value={warehouseId}
            disabled={!product.is_available}
            options={product.units.map((unit) => ({
              value: unit.warehouse_id,
              label: (
                <div className={classnames(styles.unitWrapper)}>
                  <div
                    className={classnames(styles.warehouseName, {
                      [styles.disabled]: !product.is_available
                    })}>
                    <img src={unit.warehouse_image_icon} />
                    <span>{unit.warehouse_name}</span>
                  </div>
                  {!!line && (
                    <div className={styles.unitWrapperRight}>
                      {unit.available_quantity ? (
                        <>
                          {unit.available_quantity_formatted} {unit.shortcut}
                        </>
                      ) : (
                        <span>{t('brak')}</span>
                      )}
                      &nbsp;
                      <div
                        className={classnames(styles.warehousePrice, {
                          [styles.disabled]: !product.is_available
                        })}>
                        {unit.unit_price_net_formatted} {product.currency} <Trans>netto</Trans>
                      </div>
                    </div>
                  )}
                </div>
              ),
              isDisabled: !unit.available_quantity,
              item: unit
            }))}
          />
        )}
      </div>
    );
  }, [warehouseId, line, product]);

  // funkcja renderująca przycisk dodawania produktu do listy zakupowej
  const renderAddToShoppingListButton = useCallback(
    () => (
      <div
        className={classnames(styles.shoppingListButtonWrapper, {
          [styles.shoppingListButtonWrapperLine]: !!line
        })}>
        {profile?.role !== 'ROLE_OPEN_PROFILE' && (
          <div className={styles.shoppingListWrapper}>
            <AddToShoppingListButton
              product={product as IProductListItem}
              unit={unit}
              quantity={quantity}
            />
            {!line && (
              <AddToFavouritesButton product={product} unitId={unitId} disableLabel={!!line} />
            )}
          </div>
        )}
      </div>
    ),
    [profile, quantity, unit, line]
  );

  const renderToolsWrapper = () => {
    if (profile?.role !== 'ROLE_OPEN_PROFILE' && !product.is_available) {
      return (
        <button
          className={styles.availabilityButton}
          disabled={isAvailabilityButtonDisabled}
          onClick={() =>
            addToNotAvailableList({
              product_id: product.id,
              type: 'NOT_AVAILABLE_PRODUCTS',
              unit_id: unitId,
              quantity: 1
            })
          }>
          {isAvailabilityButtonDisabled ? (
            <Tooltip className={styles.overloginTooltip} title={t('Produkt na liście powiadomień')}>
              <div>
                <Trans>Powiadom o dostępności</Trans>
              </div>
            </Tooltip>
          ) : (
            <Trans>Powiadom o dostępności</Trans>
          )}
        </button>
      );
    }

    return (
      <div
        className={classnames(styles.tools, {
          [styles.toolsLine]: !!line
        })}>
        {line && !isSearch && renderAddToShoppingListButton()}
        <div
          className={classnames(styles['counter-wrapper'], {
            [styles['counter-wrapper-line']]: line
          })}>
          {profile?.role !== 'ROLE_OPEN_PROFILE' ? (
            <Counter
              onChange={(value) => updateQuantity({ value, unit_id: unitId })}
              onDecrease={() => decrementQuantity({ value: quantity, unit_id: unitId })}
              onIncrease={() => incrementQuantity({ value: quantity, unit_id: unitId })}
              value={quantity}
              onChangeValue={(isChanged, newValue) => setManuallyChangedQuantity(newValue)}
              disabled={
                isDecrementQuantityLoading || isIncrementQuantityLoading || isUpdateQuantityLoading
              }
            />
          ) : (
            <button onClick={() => setIsWhereToBuyModal(true)} className={styles.loginButton}>
              <Trans>Gdzie kupić?</Trans>
            </button>
          )}
        </div>

        {profile?.role !== 'ROLE_OPEN_PROFILE' && (
          <AddToCartButton
            isQuantityChanges={!!manuallyChangedQuantity && manuallyChangedQuantity !== quantity}
            updateQuantity={async () => {
              const newQuantity = manuallyChangedQuantity
                ? await updateQuantityAsync({
                    value: manuallyChangedQuantity,
                    unit_id: unitId
                  })
                : undefined;

              return newQuantity?.data.value === manuallyChangedQuantity
                ? manuallyChangedQuantity
                : undefined;
            }}
            quantity={quantity}
            unitId={unitId}
            warehouseId={warehouseId}
            productId={product.id}
            disabled={isDecrementQuantityLoading || isIncrementQuantityLoading}
          />
        )}
      </div>
    );
  };

  const renderMainAttribute = (attribute: IProductMainAttribute, i: number) => {
    return (
      <li className={classnames(styles.listItem, { [styles.listItemLine]: !!line })} key={i}>
        {attribute.name}: <strong>{attribute.value}</strong>&nbsp;
      </li>
    );
  };

  const Title = () => {
    return (
      <div
        className={classnames(styles['product-title'], {
          [styles.productTitleLine]: !!line
        })}>
        <Link
          className={styles.title}
          to={`/${product.url_link}`}
          state={{ categoryId, searchKeywords }}>
          <span itemProp="name">{product.title}</span>
        </Link>
        <meta itemProp="url" content={window.location.origin + product.url_link} />
        <ul className={styles.list}>
          {product.main_attributes.map((attribute, i) => renderMainAttribute(attribute, i))}
        </ul>
        <span>
          {product.producer_name && (
            <span
              className={styles.producer}
              itemScope
              itemProp="brand"
              itemType="http://schema.org/Brand">
              {t('Producent')}: <span itemProp="name">{product.producer_name}</span>
            </span>
          )}
        </span>

        {line && isSearch && (
          <>
            <div>
              <div className={styles.descriptionLabel}>
                <Trans>Opis</Trans>
              </div>
              <div
                className={styles['product-description']}
                dangerouslySetInnerHTML={{ __html: product.description }}
              />
            </div>
          </>
        )}
        {line && !isSearch && product.producer_image && (
          <div className={classnames(styles.producerLogo, { [styles.producerLogoLine]: !!line })}>
            <img src={product.producer_image} />
          </div>
        )}

        {line && (
          <div
            className={classnames(styles.labels, {
              [styles.labelsLine]: line,
              [styles.isSearch]: isSearch
            })}>
            {product.labels.map((label) => (
              <Label key={label.type} label={label} />
            ))}
          </div>
        )}

        {minimalVariant && !disablePrice && (
          <div
            className={classnames(styles.priceWrapper, {
              [styles.priceWrapperLine]: !!line
            })}>
            {unit?.old_price_net_formatted && (
              <div
                className={classnames(styles.old, {
                  [styles.oldLine]: !!line
                })}>
                {unit.old_price_net_formatted} {product.currency}
              </div>
            )}
            <div className={styles.net}>
              <strong>
                {unit?.price_net_formatted} {product.currency}
              </strong>{' '}
              {t('netto')}
            </div>
            <div className={styles.gross}>
              {unit?.price_gross_formatted} {product.currency} {t('brutto')}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      className={classnames(
        styles.wrapperComponent,
        {
          [styles.lineWrapper]: !!line,
          [styles.slide]: !!slide
        },
        'StylePath-Components-Containers-ProductItem'
      )}
      itemProp="item"
      itemScope
      itemType="http://schema.org/Product">
      <div
        className={classnames(styles.imageTitleWrapper, {
          [styles.imageStretch]: imageStretch,
          [styles.isSearch]: !!isSearch
        })}>
        <Link
          to={`/${product.url_link}`}
          state={{ categoryId, searchKeywords }}
          className={classnames(styles['product-image'], {
            [styles.productImageLine]: !!line,
            [styles.productImageMinimal]: !!minimalVariant,
            [styles.isSearch]: !!isSearch
          })}>
          <div className={styles.imageWrapper}>
            <img
              src={product.images[0]?.min}
              onMouseEnter={() => showProductPreview && setImageHover(true)}
              onMouseLeave={() => showProductPreview && setImageHover(false)}
            />
            {!line && product.producer_image && (
              <div className={classnames(styles.producerLogo)}>
                <img src={product.producer_image} />
              </div>
            )}
          </div>

          {!line && (
            <div className={styles.labels}>
              {product.labels.map((label) => (
                <Label key={label.type} label={label} />
              ))}
            </div>
          )}
        </Link>
        <meta itemProp="image" content={product.images[0]?.min} />
        {minimalVariant && <Title />}
      </div>

      <div
        className={classnames(styles['product-details'], {
          [styles.productDetailsLine]: !!line,
          [styles.noActions]: profile?.role === 'ROLE_OPEN_PROFILE'
        })}>
        {!minimalVariant && <Title />}

        <div
          className={classnames(styles['product-actions'], {
            [styles.productActionsLine]: !!line,
            [styles.isSearch]: !!isSearch
          })}>
          {renderStockSelector()}
          {profile?.role !== 'ROLE_OPEN_PROFILE' && !minimalVariant && !disablePrice && (
            <div
              className={classnames(styles.priceWrapper, {
                [styles.priceWrapperLine]: !!line
              })}>
              {unit?.old_price_net_formatted && (
                <div
                  className={classnames(styles.old, {
                    [styles.oldLine]: !!line
                  })}>
                  {unit.old_price_net_formatted} {product.currency}
                </div>
              )}
              <div className={styles.net}>
                <strong>
                  {unit?.price_net_formatted} {product.currency}
                </strong>{' '}
                {t('netto')}
              </div>
              <div className={styles.gross}>
                {unit?.price_gross_formatted} {product.currency} {t('brutto')}
              </div>
            </div>
          )}
          <div className={styles.shoppingListWrapperLine}>
            {renderToolsWrapper()}
            {line && (
              <AddToFavouritesButton product={product} unitId={unitId} disableLabel={!!line} />
            )}
          </div>

          {!line && !minimalVariant && renderAddToShoppingListButton()}
        </div>
      </div>

      {isWhereToBuyModal && (
        <Modal title={t('Gdzie kupić?')} onClose={() => setIsWhereToBuyModal(false)}>
          <HtmlBlock sectionId="SUBPAGE" articleId="WHERE_TO_BUY" />
        </Modal>
      )}

      {isProductPreview && product && showProductPreview && !isMobile && !isTablet && (
        <ProductPreview productId={product.id} setImageHover={setImageHover} />
      )}
    </div>
  );
};

export default ProductItem;
