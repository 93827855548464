// stopka głównego layoutu

import React from 'react';
import { Trans } from 'react-i18next';
import classnames from 'classnames';

import { useRWD } from 'hooks';
import { useGetHomeHtmlBlock } from 'api/endpoints';
import { Container } from 'components/controls';
import { Link } from 'components/controls';

import facebookLogo from 'assets/facebook.svg';
import googleLogo from 'assets/google.svg';
import instagramLogo from 'assets/instagram.svg';
import senteLogo from 'assets/logo-sente.svg';
import mastercardLogo from 'assets/mastercard.svg';
import paypalLogo from 'assets/paypal.svg';
import pinterestLogo from 'assets/pinterest.svg';
import visaLogo from 'assets/visa.svg';

import styles from 'theme/components/layouts/MainLayout/components/Footer/Footer.module.scss';

const Footer = () => {
  const { isMobile } = useRWD();

  const { data: footerData } = useGetHomeHtmlBlock('HOME_FOOTER', 'FOOTER');

  const renderContent = () => {
    const contentMobile = footerData?.items.find(
      (item) => item.section_field_symbol === 'CONTENT_MOBILE'
    );
    const contentDesktop = footerData?.items.find(
      (item) => item.section_field_symbol === 'CONTENT'
    );

    if (isMobile && contentMobile) {
      return <div dangerouslySetInnerHTML={{ __html: contentMobile?.html_code || '' }} />;
    }

    if (contentDesktop) {
      return <div dangerouslySetInnerHTML={{ __html: contentDesktop?.html_code || '' }} />;
    }

    return null;
  };

  return (
    <div
      className={classnames(
        styles.componentWrapper,
        'StylePath-Components-Layouts-MainLayout-Components-Footer'
      )}
      itemScope
      itemType="http://schema.org/Organization">
      <meta itemProp="url" content={window.location.href} />
      <Container>
        <div className={styles.contentWrapper}>{renderContent()}</div>
      </Container>

      <div className={styles.bottomBar}>
        <Container>
          <div className={styles.contentWrapper}>
            <div className={styles.lists}>
              <section>
                <Trans>Rodzaje płatności</Trans>
                <span className={styles.list}>
                  <img src={visaLogo} alt="visa" />
                  <img src={mastercardLogo} alt="mastercard" />
                  <img src={paypalLogo} alt="paypal" />
                </span>
              </section>
              <section>
                <Trans>Obserwuj nas na</Trans>
                <span className={styles.list}>
                  <Link to="/wip">
                    <img src={facebookLogo} alt="facebook" />
                  </Link>
                  <Link to="/wip">
                    <img src={googleLogo} alt="google" />
                  </Link>
                  <Link to="/wip">
                    <img src={instagramLogo} alt="instagram" />
                  </Link>
                  <Link to="/wip">
                    <img src={pinterestLogo} alt="pinterest" />
                  </Link>
                </span>
              </section>
            </div>

            <section className={styles.powered}>
              powered by
              <a href="https://sente.pl" target="_blank" rel="noreferrer">
                <img src={senteLogo} alt="logo Sente" />
                <meta itemProp="logo" content={senteLogo} />
              </a>
            </section>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Footer;
