// box z cenami, jedostkami itp

import React, { FC, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import { Printer, Truck } from 'react-bootstrap-icons';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';

import { useSelector } from 'store';
import { IProduct, IUnit } from 'api/types';
import {
  useGetProductAddToCartHint,
  useGetCartDeliveryInfo,
  usePostProductQuantityDecrement,
  usePostProductQuantityIncrement,
  usePostProductQuantity,
  usePostShoppingListAddToDefaultList
} from 'api';
import { useNotifications } from 'hooks';
import { Counter, Availability, Link, Alert, Select } from 'components/controls';
import {
  AddToCartButton,
  AddToShoppingListButton,
  AddToFavouritesButton
} from 'components/containers';

import styles from 'theme/pages/Product/components/Prices/Prices.module.scss';

// typ danych wejściowych
interface IProps {
  product: IProduct;
  refetchProductData: () => void;
}

const Prices: FC<IProps> = ({ product, refetchProductData }) => {
  const { t } = useTranslation();

  const location = useLocation();
  const { showWarningMessage } = useNotifications();
  const { profile } = useSelector((state) => state.auth);

  const [isAvailabilityButtonDisabled, setIsAvailabilityButtonDisabled] = useState(
    product.is_on_not_available_products_shopping_list
  );

  // id domyślnego koszyka
  const { currentCartId } = useSelector((state) => state.cart);

  // ilość produktów
  const [quantity, setQuantity] = useState(0);

  // aktualnie zmieniona ilość (sprzed walidacji)
  const [manuallyChangedQuantity, setManuallyChangedQuantity] = useState<number>();

  // aktualna jednostka
  const unitId = product.units[0]?.unit_id;

  // ID aktualnego magazynu
  const [warehouseId, setWarehouseId] = useState<number>(product.units[0]?.warehouse_id);

  // aktualna jednostka
  const unit = useMemo(
    () => product.units.find((unit) => unit.warehouse_id === warehouseId),
    [warehouseId, product]
  );

  // ustawianie początkowego magazynu
  useEffect(() => {
    const centralWarehouseStock = product.units.find(
      (unit) => unit.is_central_warehouse
    )?.available_quantity;

    if (!centralWarehouseStock && product.is_available) {
      const firstAvailableUnit = product.units.find((unit) => !!unit.available_quantity);

      firstAvailableUnit && setWarehouseId(firstAvailableUnit?.warehouse_id);
    }
  }, []);

  // zmiana ilości po zmianie jednostki
  useEffect(() => {
    unit && setQuantity(unit.minimal_quantity || 1);
  }, [unit]);

  // pobranie informacji o zmniejsonej ilości
  const { mutate: decrementQuantity, isLoading: isDecrementQuantityLoading } =
    usePostProductQuantityDecrement(product.id, {
      onSuccess: (data) => {
        setQuantity(data.data.value);
      }
    });

  // pobranie informacji o zwiększonej ilości
  const { mutate: incrementQuantity, isLoading: isIncrementQuantityLoading } =
    usePostProductQuantityIncrement(product.id, {
      onSuccess: (data) => {
        setQuantity(data.data.value);
      }
    });

  // pobranie informacji o zwiększonej ilości
  const { mutate: updateQuantity, mutateAsync: updateQuantityAsync } = usePostProductQuantity(
    product.id,
    {
      onSuccess: (data) => {
        setQuantity(data.data.value);
      }
    }
  );

  // pobranie informacji z podpowiedzią do koszyka
  const { data: addToCartHintData } = useGetProductAddToCartHint(
    product.id,
    {
      unit_id: unitId,
      quantity
    },
    { keepPreviousData: true }
  );

  // pobranie podpowiedzi o dostawie
  const { data: cartDeliveryInfoData } = useGetCartDeliveryInfo(currentCartId || 0, {
    enabled: !!currentCartId
  });

  // dodanie do listy niedostępnych produktów
  const { mutate: addToDefaultShoppingList } = usePostShoppingListAddToDefaultList({
    onSuccess: () => {
      refetchProductData();
      setIsAvailabilityButtonDisabled(true);
    }
  });

  const isOpenProfile = profile?.role === 'ROLE_OPEN_PROFILE';

  const renderActionsWrapper = () => {
    if (profile?.role !== 'ROLE_OPEN_PROFILE' && !product.is_available) {
      return (
        <button
          className={styles.availabilityButton}
          disabled={isAvailabilityButtonDisabled}
          onClick={() =>
            addToDefaultShoppingList({
              product_id: product.id,
              type: 'NOT_AVAILABLE_PRODUCTS',
              unit_id: unitId,
              quantity: 1
            })
          }>
          {isAvailabilityButtonDisabled ? (
            <Tooltip className={styles.overloginTooltip} title={t('Produkt na liście powiadomień')}>
              <div>
                <Trans>Powiadom o dostępności</Trans>
              </div>
            </Tooltip>
          ) : (
            <Trans>Powiadom o dostępności</Trans>
          )}
        </button>
      );
    }

    return (
      <div className={styles.actionsWrapper}>
        {isOpenProfile ? (
          <Link
            to={`/login?return_url=${encodeURIComponent(location.pathname + location.search)}`}
            className={styles.loginButton}>
            <Trans>zaloguj</Trans>
          </Link>
        ) : (
          <Counter
            className={styles.quantityCounter}
            onChange={(value) => updateQuantity({ value, unit_id: unitId })}
            onDecrease={() => decrementQuantity({ value: quantity, unit_id: unitId })}
            onIncrease={() => incrementQuantity({ value: quantity, unit_id: unitId })}
            value={quantity}
            onChangeValue={(isChanged, newValue) => setManuallyChangedQuantity(newValue)}
          />
        )}

        <AddToCartButton
          isQuantityChanges={!!manuallyChangedQuantity && manuallyChangedQuantity !== quantity}
          updateQuantity={async () => {
            const newQuantity = manuallyChangedQuantity
              ? await updateQuantityAsync({
                  value: manuallyChangedQuantity,
                  unit_id: unitId
                })
              : undefined;

            return newQuantity?.data.value === manuallyChangedQuantity
              ? manuallyChangedQuantity
              : undefined;
          }}
          quantity={quantity}
          unitId={unitId}
          productId={product.id}
          warehouseId={warehouseId}
          large
          disabled={isOpenProfile || isDecrementQuantityLoading || isIncrementQuantityLoading}
        />
      </div>
    );
  };

  return (
    <div
      className={classnames(styles.wrapperComponent, 'StylePath-Pages-Product-components-Prices')}>
      <div className={styles.mainContent}>
        {!isOpenProfile && !unit?.is_central_warehouse && (
          <Alert type="info">
            <Trans>
              W przypadku produktów dostępnych w innych magazynach niż centralny obowiązują
              dodatkowe koszty wysyłki.
            </Trans>
          </Alert>
        )}

        {!isOpenProfile && (
          <div className={styles.warehouseWrapper}>
            <div className={styles.warehouseTitle}>
              <Trans>Wybierz magazyn</Trans>
            </div>
            <Select<IUnit>
              onChange={(unit) => unit?.warehouse_id && setWarehouseId(unit.warehouse_id)}
              value={warehouseId}
              options={product.units.map((unit) => ({
                value: unit.warehouse_id,
                label: (
                  <div className={classnames(styles.unitWrapper)}>
                    <div>
                      <img src={unit.warehouse_image_icon} />
                      <span>{unit.warehouse_name}</span>
                    </div>
                    <div className={styles.unitWrapperRight}>
                      {unit.available_quantity ? (
                        <>
                          {unit.available_quantity_formatted} {unit.shortcut}
                        </>
                      ) : (
                        <span id={styles.noLabel}>{t('brak')}</span>
                      )}
                      <span className={styles.dash} />
                      {unit.unit_price_net_formatted} {product.currency} <Trans>netto</Trans>
                    </div>
                  </div>
                ),
                isDisabled: !unit.available_quantity,
                item: unit
              }))}
            />
          </div>
        )}

        {!isOpenProfile && (
          <div className={styles.price}>
            <div className={styles.text}>
              <Trans>Twoja cena:</Trans>
            </div>

            <div className={styles.groupPrice}>
              <div className={styles.net}>
                {unit?.old_price_net_formatted && (
                  <div className={styles.old}>
                    {unit.old_price_net_formatted} {product.currency}
                  </div>
                )}
                <strong>
                  {unit?.price_net_formatted} {product.currency}
                </strong>{' '}
                <Trans>netto</Trans>
              </div>
              <div className={styles.gross}>
                {unit?.price_gross_formatted} {product.currency} <Trans>brutto</Trans>
              </div>
            </div>
          </div>
        )}

        {!!addToCartHintData && addToCartHintData.hint && (
          <div
            className={styles.promotion}
            style={{ borderColor: addToCartHintData.color, color: addToCartHintData.color }}>
            {addToCartHintData.hint}
          </div>
        )}

        {renderActionsWrapper()}
      </div>

      <div className={styles.dashedBlock}>
        <div className={styles.item}>
          <Availability stock={product.stock} />
        </div>

        {cartDeliveryInfoData?.info && (
          <div className={styles.item}>
            <Truck />
            {cartDeliveryInfoData.info}
          </div>
        )}
      </div>

      <div className={styles.dashedBlock}>
        <div
          className={classnames(styles.item, styles.button)}
          onClick={() => showWarningMessage('Funkcja niezaimplementowana')}>
          <Printer />
          <Trans>Drukuj</Trans>
        </div>

        {!isOpenProfile && (
          <>
            <div className={styles.item}>
              <AddToShoppingListButton
                product={product as IProduct}
                quantity={quantity}
                unit={unit}
              />
            </div>
            <div className={styles.item}>
              <AddToFavouritesButton product={product} unitId={unitId} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Prices;
