// zdjęcie obrazka katalogu

import React, { FC, useMemo, useState } from 'react';

import { useGetProductsCategory } from 'api/endpoints';
import { ICategorySubcategoryListItem } from 'api/types';
import { Modal } from 'components/controls';

import styles from 'theme/pages/Products/components/CatalogImage/CatalogImage.module.scss';

// typ danych wejściowych
interface IProps {
  categories?: ICategorySubcategoryListItem[];
  categoryId: number;
}

const CatalogImage: FC<IProps> = ({ categoryId }) => {
  const [imageUrl, setImageUrl] = useState('');

  const { data: productsCategory } = useGetProductsCategory(categoryId);

  const images = useMemo(() => {
    return productsCategory?.images.filter((image) => image.type === 'NORMAL');
  }, [productsCategory]);

  const secondaryImages = useMemo(() => {
    return productsCategory?.images.filter((image) => image.type === 'TABLE') || [];
  }, [productsCategory]);

  return (
    <>
      <div className={styles.wrapperComponent}>
        <img className={styles.mainImage} src={images?.[0]?.source} />
        <div className={styles.secondaryImages}>
          {secondaryImages.map((image, i) => (
            <div key={i} className={styles.secondaryImage}>
              <div className={styles.imageName}>{image.name}</div>
              <img src={image.thumb} onClick={() => setImageUrl(image.source)} />
            </div>
          ))}
        </div>
      </div>
      {imageUrl && (
        <Modal customWidth={1000} color="WHITE" title="" onClose={() => setImageUrl('')}>
          <img src={imageUrl} />
        </Modal>
      )}
    </>
  );
};

export default CatalogImage;
