// szczegóły produktu

import React, { FC, useState } from 'react';
import classnames from 'classnames';
import { Trans } from 'react-i18next';

import { IProduct, IProductListItem } from 'api/types';
import {
  usePostShoppingListAddToDefaultList,
  useDeleteShoppingListRemoveFromDefaultList
} from 'api';

import { HeartIcon } from 'assets/icons';
import styles from 'theme/components/containers/AddToFavouritesButton/AddToFavouritesButton.module.scss';

// typ danych wejściowych
interface IProps {
  product: IProduct | IProductListItem;
  unitId: number;
  disableLabel?: boolean;
}

const AddToFavouritesButton: FC<IProps> = ({ product, unitId, disableLabel }) => {
  const [isOnFavouriteShoppingList, setIsOnFavouriteShoppingList] = useState(
    product.is_on_favourite_shopping_list
  );
  // dodanie do listy niedostępnych produktów
  const { mutate: addToDefaultShoppingList } = usePostShoppingListAddToDefaultList({
    onSuccess: () => {
      setIsOnFavouriteShoppingList(!isOnFavouriteShoppingList);
    }
  });

  const { mutate: removeProductFromFavourites } = useDeleteShoppingListRemoveFromDefaultList(
    'FAVORITES',
    product.id,
    unitId,
    {
      onSuccess: () => {
        setIsOnFavouriteShoppingList(!isOnFavouriteShoppingList);
      }
    }
  );

  const renderLabel = () => {
    if (!disableLabel) {
      return !isOnFavouriteShoppingList ? (
        <Trans>Dodaj do ulubionych</Trans>
      ) : (
        <Trans>Usuń z ulubionych</Trans>
      );
    }

    return null;
  };

  return (
    <div
      className={classnames(styles.wrapperComponent, {
        [styles.isOnFavouriteShoppingList]: isOnFavouriteShoppingList
      })}
      onClick={() => {
        if (!isOnFavouriteShoppingList) {
          addToDefaultShoppingList({
            product_id: product.id,
            type: 'FAVORITES',
            unit_id: unitId,
            quantity: 1
          });

          return;
        }

        removeProductFromFavourites();
      }}>
      <div>
        <HeartIcon />
      </div>
      {renderLabel()}
    </div>
  );
};

export default AddToFavouritesButton;
